import VueMultiselect from "@/components/vue-multiselect";
import axios from "../../../../../axios/osint.js";
import {DateTime} from "luxon";
import companyInfo from "@/views/research/tools/company-identifier/db/companyInfo";
import financialStrength from "@/views/research/tools/company-identifier/db/financialStrength";
export default {
    name: "db",
    components: {
        VueMultiselect,
        companyInfo,
        financialStrength,
    },
    props: {
        companyDetails: Object,
    },
    data() {
        return {
            levels: [
                {
                    group: "level",
                    categories: [
                        {
                            text: "L1",
                            value: "L1",
                        },
                        {
                            text: "L2",
                            value: "L2",
                            $isDisabled: true,
                        },
                        {
                            text: "L3",
                            value: "L3",
                        },
                    ],
                },
            ],
            companyInfoLevel: {
                text: "L1",
                value: "L1",
            },
            financialsInfoLevel: {
                text: "L1",
                value: "L1",
            },
            companyInfo: [],
            principalsAndContacts: [],
            financialStrengthInsightsInfo: [],
            principalsAndContactsTabs: ["Principals", "Summary"],
            selectedPrincipalTab: "Principals",
            companyInfoTabs: [],
            disableFetchMore: false,
            disableFetchCompanyInfo: false,
            companyFetchmore: false,
            fetchprincipals: false,
            fetchfinancials: false,
            fetchCompanyExpanded: false,
            fetchPrincipalExpanded: false,
            fetchFinancialExpanded: false,
            financialsLevel: {},
            companyLevel: {},
        };
    },

    methods: {
        async getMoreCompanyInfo() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.companyInfoLevel.value,
            };
            const headers = {
                "x-tool-name": this.$route.params.toolName,
                "x-visited": true,
                "x-refresh": false,
            };
            let response = await axios.post("/db/data_block/company_info", requestBody);
            if (response && response.data) {
                this.companyInfo = response.data.company_info.organization;
                this.companyFetchmore = true;
                this.fetchCompanyExpanded = true;
                this.companyLevel = this.companyInfoLevel;
                if (this.companyInfoLevel.value === "L3") this.disableFetchCompanyInfo = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                if (this.companyInfoLevel.value === "L3") this.disableFetchCompanyInfo = true;
            }
        },
        handlePanelExpand(panel) {
            if (panel === "companyInfo") this.fetchCompanyExpanded = !this.fetchCompanyExpanded;
            if (panel === "principalContact") this.fetchPrincipalExpanded = !this.fetchPrincipalExpanded;
            if (panel === "financial") this.fetchFinancialExpanded = !this.fetchFinancialExpanded;
        },
        async getPrincipalsContacts() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: "L2",
            };
            const headers = {
                "x-tool-name": this.$route.params.toolName,
                "x-visited": true,
                "x-refresh": false,
            };
            let response = await axios.post("/db/data_block/principals_contacts", requestBody);
            if (response && response.data) {
                this.principalsAndContacts = response.data.principals_contacts.organization;
                this.fetchPrincipalExpanded = true;
                this.fetchprincipals = true;
            } else if (response && response.data.length === 0) this.$toast.error("No data found");
        },
        async getFinancialStrength() {
            let requestBody = {
                query_id: this.companyDetails?.api_all_data?.query_id,
                doc_id: this.companyDetails.api_all_data.doc_id,
                duns: this.companyDetails.duns_number,
                code: this.companyDetails.jurisdiction,
                level: this.financialsInfoLevel.value,
            };
            const headers = {
                "x-tool-name": this.$route.params.toolName,
                "x-visited": true,
                "x-refresh": false,
            };
            let response = await axios.post("/db/data_block/financial_strength", requestBody);
            if (response && response.data) {
                this.financialStrengthInsightsInfo = response.data.financial_detail.organization;
                this.fetchfinancials = true;
                this.fetchFinancialExpanded = true;
                this.financialsLevel = this.financialsInfoLevel;
                if (this.financialsInfoLevel.value === "L3") this.disableFetchMore = true;
            } else if (response && response.data.length === 0) {
                this.$toast.error("No data found");
                if (this.financialsInfoLevel.value === "L3") this.disableFetchMore = true;
            }
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("MMM dd yyyy");
            return dt;
        },
        selectedInfo(info) {
            this.selectedPrincipalTab = info;
        },
        fetchMoreButton(block) {
            if (block === "company") this.companyFetchmore = false;
            if (block === "financials") this.fetchfinancials = false;
        },
    },
};
